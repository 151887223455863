<template>
  <v-dialog
    v-model="show"
    max-width="1000px"
  >
    <v-card>
      <v-toolbar
        flat
        dense
        color="grey lighten-2"
      >
        <v-toolbar-title>
          {{ title }}
          <span
            v-if="receiptId"
            class="body-2"
          >
            <i>#{{ receiptId.id }}</i>
          </span>
        </v-toolbar-title>
        <v-spacer />
        <v-toolbar-items>
          <v-progress-linear
            v-if="loading"
            color="primary"
            absolute
            bottom
            indeterminate
          />
          <v-btn
            icon
            small
            @click="show = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pt-4 text-center">
        <validation-observer ref="observer">
          <v-row>
            <v-col>
              <v-menu
                v-model="commitmentReferenceMonthMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="commitmentReferenceMonth"
                  >
                    <v-text-field
                      v-model="commitmentReferenceMonthFormated"
                      v-mask="'##/####'"
                      label="Mês de referência do aviso de empenho"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="receipt.commitmentReferenceMonth = dateFormatIsoMonth(commitmentReferenceMonthFormated)"
                      @click:append="commitmentReferenceMonthMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="receipt.commitmentReferenceMonth"
                  no-title
                  type="month"
                  class="ma-0"
                  @input="commitmentReferenceMonthMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="commitmentBillingDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="commitmentBillingDate"
                  >
                    <v-text-field
                      v-model="commitmentBillingDateFormated"
                      v-mask="'##/##/####'"
                      label="Data da cobrança do aviso de empenho"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="receipt.commitmentBillingDate = dateFormatIso(commitmentBillingDateFormated)"
                      @click:append="commitmentBillingDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="receipt.commitmentBillingDate"
                  no-title
                  class="ma-0"
                  @input="commitmentBillingDateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-menu
                v-model="receiptReferenceMonthMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="receiptReferenceMonth"
                  >
                    <v-text-field
                      v-model="receiptReferenceMonthFormated"
                      v-mask="'##/####'"
                      label="Mês de referência do recibo"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="receipt.receiptReferenceMonth = dateFormatIsoMonth(receiptReferenceMonthFormated)"
                      @click:append="receiptReferenceMonthMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="receipt.receiptReferenceMonth"
                  no-title
                  type="month"
                  class="ma-0"
                  @input="receiptReferenceMonthMenu = false"
                />
              </v-menu>
            </v-col>
            <v-col>
              <v-menu
                v-model="receiptSignatureDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="receiptSignatureDate"
                  >
                    <v-text-field
                      v-model="receiptSignatureDateFormated"
                      v-mask="'##/##/####'"
                      label="Data da assinatura do recibo"
                      hide-details="auto"
                      :error-messages="errors"
                      append-icon="mdi-calendar"
                      @blur="receipt.receiptSignatureDate = dateFormatIso(receiptSignatureDateFormated)"
                      @click:append="receiptSignatureDateMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="receipt.receiptSignatureDate"
                  no-title
                  class="ma-0"
                  @input="receiptSignatureDateMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="commitmentContributionRangeYear"
              >
                <v-text-field
                  v-model="receipt.commitmentContributionRangeYear"
                  v-mask="'####'"
                  label="Ano tabela de contribuição empenho"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
            <v-col>
              <validation-provider
                v-slot="{ errors }"
                vid="receiptContributionRangeYear"
              >
                <v-text-field
                  v-model="receipt.receiptContributionRangeYear"
                  v-mask="'####'"
                  label="Ano tabela de contribuição recibo"
                  hide-details="auto"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <v-menu
                v-model="searchMonthMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{}">
                  <validation-provider
                    v-slot="{ errors }"
                    vid="searchMonth"
                  >
                    <v-text-field
                      v-model="searchMonthFormated"
                      v-mask="'##/####'"
                      label="Mês da consulta"
                      hide-details="auto"
                      :error-messages="errors"
                      hint="Mês utilizado como referência na pesquisa dos recibos"
                      persistent-hint
                      append-icon="mdi-calendar"
                      @blur="receipt.searchMonth = dateFormatIsoMonth(searchMonthFormated)"
                      @click:append="searchMonthMenu = true"
                    />
                  </validation-provider>
                </template>
                <v-date-picker
                  v-model="receipt.searchMonth"
                  no-title
                  type="month"
                  class="ma-0"
                  @input="searchMonthMenu = false"
                />
              </v-menu>
            </v-col>
          </v-row>
        </validation-observer>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn
          v-if="!loading"
          color="success"
          outlined
          :loading="loadingSave"
          @click="save"
        >
          <v-icon
            v-text="'mdi-content-save'"
            left
          />
          Salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import dateFormat from '@/utils/date-format'
  import receiptsApi from '@/api/receipts'

  export default {

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      receiptId: {
        type: Number,
        default: 0,
      },
    },

    data () {
      return {
        loading: false,
        loadingSave: false,
        // menus dates
        commitmentReferenceMonthMenu: false,
        commitmentBillingDateMenu: false,
        receiptReferenceMonthMenu: false,
        receiptSignatureDateMenu: false,
        searchMonthMenu: false,
        // formated dates
        commitmentReferenceMonthFormated: null,
        commitmentBillingDateFormated: null,
        receiptReferenceMonthFormated: null,
        receiptSignatureDateFormated: null,
        searchMonthFormated: null,
        // others
        dateFormatIso: dateFormat.iso,
        dateFormatIsoMonth: dateFormat.isoMonth,
        receipt: {},
      }
    },

    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        },
      },
      title () {
        return this.receiptId ? 'Editar' : 'Adicionar'
      }
    },

    watch: {
      show (val) {
        if (!val) return

        this.load()
      },
      'receipt.commitmentReferenceMonth': {
        immediate: true,
        handler (newVal) {
          this.commitmentReferenceMonthFormated = dateFormat.ptBrMonth(newVal)
        },
      },
      'receipt.commitmentBillingDate': {
        immediate: true,
        handler (newVal) {
          this.commitmentBillingDateFormated = dateFormat.ptBr(newVal)
        },
      },
      'receipt.receiptReferenceMonth': {
        immediate: true,
        handler (newVal) {
          this.receiptReferenceMonthFormated = dateFormat.ptBrMonth(newVal)
        },
      },
      'receipt.receiptSignatureDate': {
        immediate: true,
        handler (newVal) {
          this.receiptSignatureDateFormated = dateFormat.ptBr(newVal)
        },
      },
      'receipt.searchMonth': {
        immediate: true,
        handler (newVal) {
          this.searchMonthFormated = dateFormat.ptBrMonth(newVal)
        },
      },
    },

    methods: {

      async load () {
        try {
          this.receipt = {
            commitmentReferenceMonth: null,
            commitmentBillingDate: null,
            receiptReferenceMonth: null,
            receiptSignatureDate: null,
            searchMonth: null,
            commitmentContributionRangeYear: null,
            receiptContributionRangeYear: null,
          }

          if (!this.receiptId) {
            return
          }

          this.loading = true

          const response = await receiptsApi.get(this.receiptId)
          const receipt = { ...response.data.receipt }
          receipt.commitmentReferenceMonth = receipt.commitmentReferenceMonth?.slice(0, -3)
          receipt.receiptReferenceMonth = receipt.receiptReferenceMonth?.slice(0, -3)
          receipt.searchMonth = receipt.searchMonth?.slice(0, -3)

          this.receipt = receipt
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async save () {
        try {
          this.loadingSave = true

          this.$refs.observer.reset()

          const receipt = { ...this.receipt }
          receipt.commitmentReferenceMonth = `${receipt.commitmentReferenceMonth}-01`
          receipt.receiptReferenceMonth = `${receipt.receiptReferenceMonth}-01`
          receipt.searchMonth = `${receipt.searchMonth}-01`

          if (this.receiptId) {
            await receiptsApi.update(this.receiptId, receipt)
          } else {
            await receiptsApi.insert(receipt)
          }

          this.show = false
          this.$snackbar.show({
            color: 'success',
            message: this.receiptId ? this.$messages._('update_success') : this.$messages._('new_success')
          })
          this.$emit('save')
        } catch (e) {
          if (e.response.status === 422) {
            this.$refs.observer.setErrors(this.$apiError.fieldsValidation(e))
            return
          }

          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingSave = false
        }
      },

    },

  }
</script>
